import { useState, useEffect, memo } from 'react'
import { useRouter } from 'next/router'
import { domAnimation, LazyMotion, m } from 'framer-motion'
import { GameMode, MM_MAX_PLAYER_LIMIT } from '@istvan-kreisz/hnsw-library'
import { is } from 'superstruct'
import Image from 'next/image'
import Link from 'next/link'
import InfoText from '../../shared/components/InfoText'

const gameModeCards = ({
	style,
	parentCallback,
	activePlayerCountMM,
}: {
	style: 'gamesetup' | 'homepage'
	parentCallback?: (gamemode: GameMode) => void
	activePlayerCountMM: number | undefined
}) => {
	const router = useRouter()
	const [SelectedGameCard, setSelectedGameCard] = useState<GameMode | ''>('')

	const gameCardClicked = (gamemode: GameMode) => {
		setSelectedGameCard(gamemode)
		parentCallback(gamemode)
	}

	const ConditionalAnimation = ({ gamemode, wrapper, children }) => {
		//Only show card hover animations if in Game Setup and gamemode has yet to be selected
		if (style === 'gamesetup' && gamemode === '') {
			return wrapper(children)
		}
		return children
	}

	const gotoGamesetup = (gamemode: GameMode) => {
		router.push({
			pathname: '/gamesetup',
			query: { gamemode: gamemode },
		})
	}

	const playclassicClicked = () => {
		gotoGamesetup('classic')
	}

	const playfindwollyClicked = () => {
		gotoGamesetup('findwolly')
	}

	const playKingOfTheHillClicked = () => {
		gotoGamesetup('kingoftheworld')
	}

	useEffect(() => {
		if (is(router.query.gamemode, GameMode)) {
			setSelectedGameCard(router.query.gamemode)
		}
	}, [router.query.gamemode])

	return (
		<section
			className={`flex-wrap justify-items-center center items-center mx-auto relative ${
				style === 'gamesetup'
					? 'flex flex-row max-w-7xl justify-center gap-x-12'
					: 'grid grid-row-1 md:grid-cols-2 max-w-5xl gap-x-5'
			}`}>
			<ConditionalAnimation
				gamemode={SelectedGameCard}
				wrapper={(children) => (
					<LazyMotion features={domAnimation}>
						<m.a
							className='col-span-1'
							tabIndex={0}
							whileHover={{ scale: 1.05 }}
							whileFocus={{ scale: 1.05 }}
							onClick={gameCardClicked.bind(null, 'classic')}>
							{children}
						</m.a>
					</LazyMotion>
				)}>
				<div
					className={`card h-[480px] relative col-span-1 bg-white max-w-sm flex rounded-4xl border-orangesun shadow-lg shadow-orangesun/20 border-2  ${
						SelectedGameCard === '' && style === 'gamesetup'
							? 'border-opacity-70 hover:border-4 hover:border-opacity-100'
							: SelectedGameCard === 'classic'
							? 'scale-105 border-4 border-opacity-100 -translate-y-3'
							: style === 'gamesetup'
							? 'border-opacity-70 opacity-50'
							: 'border-opacity-70'
					} ${style === 'gamesetup' ? 'my-5' : 'my-10'}`}>
					<figure className='overflow-hidden'>
						<Image
							loading={style === 'gamesetup' ? 'eager' : 'lazy'}
							height={292}
							width={380}
							className='-mt-7 ml-3'
							style={{ transform: 'scale(1.1)' }}
							src='/images/classicmatch.svg'
							alt={"Classic Hide 'N Seek"}></Image>
					</figure>
					<div className='card-body pb-14'>
						<div className='flex'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='fill-current text-orange mt-1 mr-2 w-6 h-6'>
								<path d='M8 24l3-9h-9l14-15-3 9h9l-14 15z' />
							</svg>
							<h2
								className={`card-title font-display  font-bold ${
									style === 'gamesetup' ? 'text-3xl' : 'text-2xl'
								}`}>
								{"Classic Hide 'N Seek"}
							</h2>
							<button
								onClick={playclassicClicked}
								className={`btn btn-lg bg-orange hover:bg-orangesun rounded-2xl h-8 ml-auto px-5 border-none ${
									style === 'gamesetup' ? 'hidden' : ''
								}`}>
								PLAY
							</button>
						</div>
						<p className='text-sm font-semibold '>
							Find your friends in different countries around the world and explore
							the world with Google Street View!
						</p>
						<div className='flex flex-row gap-x-2 font-semibold text-sm mt-7 -mb-6'>
							<div className='flex flex-row badge-ghost w-fit align-middle rounded-full text-white bg-darkerblue/70 px-2 gap-x-2'>
								<Image
									src={'/images/icons/players.svg'}
									alt='Players'
									height={18}
									width={18}
								/>
								2 - 20
							</div>
							<div className='flex flex-row badge-ghost w-fit align-middle rounded-full text-white bg-orange/90 px-2 gap-x-2'>
								Casual Silly Fun
							</div>
						</div>
					</div>
				</div>
			</ConditionalAnimation>
			{/*Find Wolly Game Card*/}
			<ConditionalAnimation
				gamemode={SelectedGameCard}
				wrapper={(children) => (
					<LazyMotion features={domAnimation}>
						<m.a
							className='col-span-1'
							tabIndex={0}
							whileHover={{ scale: 1.05 }}
							whileFocus={{ scale: 1.05 }}
							onClick={gameCardClicked.bind(null, 'findwolly')}>
							{children}
						</m.a>
					</LazyMotion>
				)}>
				<div
					className={`card h-[480px] relative col-span-1 bg-white max-w-sm flex rounded-4xl border-redmascot shadow-lg shadow-redmascot/20 border-2 ${
						SelectedGameCard === '' && style === 'gamesetup'
							? 'border-opacity-50 hover:border-4 hover:border-opacity-100'
							: SelectedGameCard === 'findwolly'
							? 'scale-105 border-4 border-opacity-100 -translate-y-3'
							: style === 'gamesetup'
							? 'border-opacity-50 opacity-50'
							: 'border-opacity-50'
					} ${style === 'gamesetup' ? 'my-5' : 'my-10'}`}>
					<figure className='overflow-hidden'>
						<Image
							loading={style === 'gamesetup' ? 'eager' : 'lazy'}
							height={292}
							width={380}
							className='-mt-10'
							style={{ transform: 'scale(1.05)' }}
							src='/images/findwolly.svg'
							alt='find wolly'></Image>
					</figure>
					<div className='card-body'>
						<div className='flex'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='fill-current text-redmascot mt-1 mr-3 w-6 h-6'
								style={{ transform: 'scale(-1,1)' }}>
								<path d='M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z' />
							</svg>
							<h2
								className={`card-title font-display  font-bold ${
									style === 'gamesetup' ? 'text-3xl' : 'text-2xl'
								}`}>
								Find Wolly
							</h2>
							<button
								onClick={playfindwollyClicked}
								className={`btn btn-lg bg-redmascot hover:bg-redmascot hover:opacity-80 rounded-2xl h-8 ml-auto px-5 border-none ${
									style === 'gamesetup' ? 'hidden' : ''
								}`}>
								PLAY
							</button>
						</div>
						<p className='text-sm font-semibold '>
							Wolly is our cheeky, lovable mascot who loves to play hide and seek
							online. Compete against others to find him!
						</p>
						<div className='flex flex-row gap-x-2 font-semibold text-sm mt-2'>
							<div className='flex flex-row badge-ghost w-fit align-middle rounded-full text-white bg-darkerblue/70 px-2 gap-x-2'>
								<Image
									src={'/images/icons/players.svg'}
									alt='Players'
									height={18}
									width={18}
								/>
								2 - 20
							</div>
							<div className='flex flex-row badge-ghost w-fit align-middle rounded-full text-white bg-redmascot/80 px-2 gap-x-2'>
								Competitive with Friends
							</div>
						</div>
					</div>
				</div>
			</ConditionalAnimation>
			{/*King of the World*/}
			<ConditionalAnimation
				gamemode={SelectedGameCard}
				wrapper={(children) => (
					<LazyMotion features={domAnimation}>
						<m.a
							className=''
							tabIndex={0}
							whileHover={{ scale: 1.05 }}
							whileFocus={{ scale: 1.05 }}
							onClick={gameCardClicked.bind(null, 'kingoftheworld')}>
							{children}
						</m.a>
					</LazyMotion>
				)}>
				<div
					className={`card h-[480px] relative max-w-sm mx-auto w-full flex rounded-4xl border-violet bg-white shadow-lg shadow-purple/20 border-2 overflow-hidden ${
						SelectedGameCard === '' && style === 'gamesetup'
							? 'border-opacity-70 hover:border-4 hover:border-opacity-100'
							: SelectedGameCard === 'kingoftheworld'
							? 'scale-105 border-4 border-opacity-100 -translate-y-3'
							: style === 'gamesetup'
							? 'border-opacity-70 opacity-50'
							: 'border-opacity-70 md:col-span-2 md:h-[420px] md:max-w-[910px]'
					} ${style === 'gamesetup' ? 'my-5' : 'my-10'}`}>
					<figure className='overflow-hidden relative'>
						<div
							className={`h-[292px] w-full relative ${
								style === 'homepage' ? 'md:hidden' : ''
							}`}>
							<Image
								loading={style === 'gamesetup' ? 'eager' : 'lazy'}
								fill
								style={{ transform: 'scale(1.1)' }}
								src='/images/kingoftheworld-sm.svg'
								alt='king of the world match'></Image>
						</div>
						<div
							className={`h-[280px] w-full relative hidden ${
								style === 'homepage' ? 'md:block' : ''
							}`}>
							<Image
								loading={style === 'gamesetup' ? 'eager' : 'lazy'}
								fill
								className={` ml-4 object-cover`}
								style={{ transform: 'scale(1.1)' }}
								src='/images/kingoftheworld.svg'
								alt='king of the world match'></Image>
						</div>
						{activePlayerCountMM && (
							<div
								className={`absolute badge-ghost w-fit flex flex-row bottom-2 align-middle rounded-full bg-white items-center px-1 gap-x-1 ' ${
									style === 'homepage' ? 'ml-5 ' : ''
								}`}>
								<span className='rounded-full w-4 h-4 bg-aquagreen animate-pulse border-1'></span>

								<span className='text-sm font-semibold'>{`${activePlayerCountMM} in Public Room`}</span>
							</div>
						)}
					</figure>

					<div className='card-body bg-white max-h-min'>
						<div className='flex'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='fill-current text-violet mt-1 mr-2 w-6 h-6'>
								<path d='M22 19v3h-20v-3h20zm0-15c-1.5 0-2.662 1.685-1.598 3.194.535.759.406 1.216.045 1.749-.765 1.127-1.872 2.057-3.447 2.057-2.521 0-3.854-2.083-4.131-3.848-.096-.614-.15-1.074.436-1.644.402-.39.695-.904.695-1.508 0-1.104-.896-2-2-2s-2 .896-2 2c0 .604.293 1.118.695 1.508.586.57.531 1.03.436 1.644-.277 1.765-1.61 3.848-4.131 3.848-1.575 0-2.682-.93-3.447-2.058-.362-.532-.491-.989.045-1.748 1.064-1.509-.098-3.194-1.598-3.194-1.104 0-2 .896-2 2 0 .797.464 1.495 1.144 1.808.825.38.856 1.317.856 2.171v7.021h20v-7.021c0-.854.031-1.792.856-2.171.68-.313 1.144-1.011 1.144-1.808 0-1.104-.896-2-2-2z' />
							</svg>
							<h2 className='card-title font-display text-3xl font-bold ml-1'>
								King of the World
							</h2>
							<Link
								className='flex justify-center'
								title='Learn More about King of the World'
								href='/blog/new-game-mode-king-of-the-world'
								target='_blank'>
								<InfoText addButtonEffect={true}></InfoText>
							</Link>
							<button
								onClick={playKingOfTheHillClicked}
								className={`btn btn-lg bg-purple hover:bg-violet rounded-2xl h-8 ml-auto px-5 border-none ${
									style === 'gamesetup' ? 'hidden' : ''
								}`}>
								PLAY
							</button>
						</div>
						<p className='text-sm font-semibold'>
							{`Massive multiplayer mode with up to ${MM_MAX_PLAYER_LIMIT} players in one room! Can you
								rise to the top and become king of the world?`}
						</p>
						<div className='flex flex-row gap-x-2 font-semibold text-sm mt-2'>
							<div className='flex flex-row badge-ghost w-fit align-middle rounded-full text-white bg-darkerblue/70 px-2 gap-x-2'>
								<Image
									src={'/images/icons/players.svg'}
									alt='Players'
									height={18}
									width={18}
								/>
								2 - {MM_MAX_PLAYER_LIMIT}
							</div>
							<div className='flex flex-row badge-ghost w-fit align-middle rounded-full text-white bg-violet/90 px-2 gap-x-2'>
								Massive Competitive
							</div>
						</div>
					</div>
				</div>
			</ConditionalAnimation>
		</section>
	)
}

export default memo(gameModeCards)
